import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import {

  SiLinux,
  SiVisualstudiocode,
  SiPostman,
  SiWebstorm,
  SiGnubash,
} from "react-icons/si";

const techDetails = {
  SiLinux: "Used Linux for server management and development",
  SiVisualstudiocode: "Developed projects using VS Code as the primary IDE.",
  SiPostman: "Tested and debugged APIs using Postman.",
  SiWebstorm: "Used WebStorm for advanced JavaScript development.",
  SiGnubash: "Worked with GNU Bash for scripting and automation."
};

function Techstack() {
  const [hovered, setHovered] = useState(null);

  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {Object.entries({
        SiLinux,
        SiVisualstudiocode,
        SiPostman,
        SiWebstorm,
        SiGnubash,
      }).map(([key, Icon]) => (
        <Col xs={4} md={2} className="tech-icons" key={key}>
          <div
            onMouseEnter={() => setHovered(key)}
            onMouseLeave={() => setHovered(null)}
            style={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            <Icon />
            {hovered === key && (
              <div style={{
                position: "absolute",
                background: "rgba(0, 0, 0, 0.75)",
                color: "white",
                padding: "5px",
                borderRadius: "5px",
                fontSize: "10px",
                textAlign: "center",
                maxWidth: "80px",
                overflow: "hidden"
              }}>
                {techDetails[key]}
              </div>
            )}
          </div>
        </Col>
      ))}
    </Row>
  );
}

export default Techstack;
