import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <div className="type-container">
      <Typewriter
        options={{
          strings: [
            "Innovator in Fintech & Digital Banking",
            "Fintech Backend Engineer",
            "AI & Machine Learning Enthusiast",
            "Passionate about Building Scalable Systems",
            "Exploring the Future of AI in Finance",
            "Cricket Enthusiast",
            "Lifelong Learner in Tech & AI",
            "Tech Geek with a Love for Innovation",
            "Driving Digital Transformation with AI",
            "Curious about Generative AI & LLMs",
          ],
          autoStart: true,
          loop: true,
          deleteSpeed: 90,
        }}
      />
    </div>
  );
}

export default Type;
