import React from "react";
import Card from "react-bootstrap/Card";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FaUniversity, FaBriefcase } from "react-icons/fa";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            <span className="purple">I am</span> a passionate software engineer and web developer with expertise in <span className="purple">Node.js</span> and the Express framework. I hold a <span className="purple">BSc in Computer Science and Information Technology (CSIT)</span> from <span className="purple">St. Lawrence College, Nepal (2019-2023)</span>. I specialize in building fintech solutions and web applications, utilizing advanced technologies such as gRPC and microservices architecture.
            <br /><br />
            I am deeply passionate about the <span className="purple">future of AI</span> and continuously explore groundbreaking advancements in artificial intelligence. My dedication to innovation, coupled with a relentless pursuit of knowledge, drives me to stay ahead in the ever-evolving tech industry. Currently, I reside in <span className="purple">Kathmandu, Nepal</span>, where I immerse myself in coding and collaborating with like-minded professionals to build impactful solutions. I thrive on pushing the boundaries of what technology can achieve, particularly in the fields of AI and fintech.
          </p>
          <br />
          <p style={{ textAlign: "center", fontSize: "1.5rem", fontWeight: "bold" }}>
            <span className="purple">My Journey</span>
          </p>
          <VerticalTimeline>
          <VerticalTimelineElement
  className="vertical-timeline-element--education custom-date-position"
  date="2019 - 2023"
  iconStyle={{ background: "#6a1b9a", color: "#fff" }}
  contentStyle={{
    background: "rgba(255, 255, 255, 0.1)",
    backdropFilter: "blur(10px)",
    color: "#fff",
    border: "1px solid rgba(255, 255, 255, 0.2)",
    boxShadow: "0 4px 10px rgba(255, 255, 255, 0.1)",
    borderRadius: "12px",
    transition: "all 0.3s ease-in-out",
  }}
  icon={<FaUniversity />}
>
  <h3 className="vertical-timeline-element-title">St. Lawrence College, Nepal</h3>
  <h4 className="vertical-timeline-element-subtitle">BSc CSIT</h4>
  <p>Studied Computer Science and Information Technology, focusing on software development, algorithms, and AI.</p>
</VerticalTimelineElement>

            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              date="2021 - Present"
              iconStyle={{ background: "#0077b6", color: "#fff" }}
              contentStyle={{
                background: "rgba(255, 255, 255, 0.1)",
                backdropFilter: "blur(10px)",
                color: "#fff",
                border: "1px solid rgba(255, 255, 255, 0.2)",
                boxShadow: "0 4px 10px rgba(255, 255, 255, 0.1)",
                borderRadius: "12px",
                transition: "all 0.3s ease-in-out",
              }}
              icon={<FaBriefcase />}
            >
              <h3 className="vertical-timeline-element-title">Swift Technology</h3>
              <h4 className="vertical-timeline-element-subtitle">Software Engineer</h4>
              <p>Building fintech solutions, implementing microservices architecture, and integrating thirdpary Api into applications.</p>
            </VerticalTimelineElement>

 
          </VerticalTimeline>
          <p style={{ color: "rgb(155 126 172)", textAlign: "center" }}>
            <span className="purple"> "Good code is its own best documentation. As you’re about to add a comment, ask yourself, ‘How can I improve the code so that this comment isn’t needed?’" </span>
          </p>
          <footer className="blockquote-footer text-center">Abishek Paudel</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
