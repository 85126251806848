import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/abishekpaudel.png";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about" style={{ paddingBottom: "0" }}>
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em", fontFamily: "'Poppins', sans-serif" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body" style={{ fontFamily: "'Open Sans', sans-serif" }}>
              Hey there! 👋 I'm a passionate developer who loves turning ideas into reality through code. 
              Whether it's building robust backend systems, crafting efficient APIs, or diving into databases, 
              I thrive on solving complex problems and creating scalable solutions.
              <br />
              <br />
              I'm fluent in technologies like{" "}
              <i>
                <span className="purple">JavaScript, Node.js, MySQL, MongoDB, Neo4j, and C++.</span>
              </i>{" "}
              I've also worked extensively with <b className="purple">gRPC</b> and{" "}
              <b className="purple">Protocol Buffers</b> to build high-performance microservices.
              <br />
              <br />
              My expertise lies in designing and implementing backend systems, crafting efficient database queries, 
              and leveraging modern frameworks like <b className="purple">Express.js</b>. I also enjoy working with 
              graph databases like <b className="purple">Neo4j</b> to build recommendation systems and other 
              data-driven applications.
              <br />
              <br />
              When I'm not coding, you'll find me exploring the latest in AI and machine learning, 
              or cheering for my favorite cricket team! 🏏
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <img
              src={myImg}
              className="img-fluid"
              alt="avatar"
              style={{ width: "100%", height: "auto", marginTop: "-140px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social" style={{ marginTop: "-50px" }}>
            {/* <h1 style={{ fontFamily: "'Poppins', sans-serif" }}>FIND ME ON</h1> */}
            {/* <p style={{ fontFamily: "'Open Sans', sans-serif" }}>
              Feel free to <span className="purple">connect </span>with me!
            </p> */}
            {/* <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/avhishek-49"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/abishek-paudel-72a43b1b2/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
            </ul> */}
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Home2;